import React from "react";

export default function EndingPage() {
    return (
        <div className={`title-page ended`}>
            <h1>with</h1>
            <h2>daniela carler</h2>
            <h2></h2>
            <h2></h2>
            <h2>eliette harris</h2>
            <h2>susanna griffin</h2>
            <h2>melanie gruwez</h2>
            <h2>pauline herold</h2>
            <h2></h2>
            <h2></h2>
            <h2>tua holappa</h2>
        </div>
    )
}